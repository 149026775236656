<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Value: "Valor",
      Level: "Nível",
      Bonus: "Bônus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Value: "Valor",
      Level: "Nivel",
      Bonus: "Bonus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList, Stat, VueMonthlyPicker },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format("MMM/YYYY"),
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      date: moment().format("YYYY-MM"),

      table: {
        heade: [
          "Usuário",
          "Nome",
          "Pedido",
          "Data",
          "Points",
          "Nível",
          "%",
          "Bonus",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    showDate(date) {
      this.date = date;
    },
    getList() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("report/equiparacao?date=" + this.date)
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                icon: "monthly",
                title: "Status",
                value: response.data.monthly,
              },
              {
                icon: "graduation",
                title: "Graduação",
                value: response.data.graduation.name,
              },
              {
                icon: "total",
                title: "Total",
                value: this.$options.filters.currency(response.data.total),
              },
            ];
          } else if (response.data.status == "empty") {
            this.statData = [];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format("YYYY-MM");
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Equiparação</h4>
          <div class="page-title-right">
            <vue-monthly-picker
              v-model="calendar"
              dateFormat="MMM/YYYY"
              :placeHolder="monthly.placeHolder"
              :monthLabels="monthly.labels"
              :clearOption="false"
              alignment="center"
            ></vue-monthly-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td
                      v-for="(data, row) in td"
                      :key="row"
                      class="notranslate"
                    >
                      <div v-if="row === 'bonus'">
                        {{ data | currency }}
                      </div>
                      <div v-else>
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>